<ng-container *ngIf="isConfirmationDialog; else onlyDone">
    <button tabindex="-1" type="button" id="no" class="btn btn-primary btn-action-confirm ml-1" [mat-dialog-close]="noDialogCloseValue" (click)="onNoClick()" [disabled]="noBtnDisable">
        {{ noBtnTitle | translate }}
    </button>
    <button tabindex="-1" type="button" id="yes" class="btn btn-primary btn-action-confirm mr-1" [mat-dialog-close]="yesDialogCloseValue" (click)="onYesClick()" [disabled]="yesBtnDisable">
        {{ yesBtnTitle | translate }}
    </button>
</ng-container>
<ng-template #onlyDone>
    <button tabindex="-1" type="button" id="done" class="btn btn-primary btn-action-confirm" [mat-dialog-close]="doneDialogCloseValue" (click)="onDoneClick()" [disabled]="doneBtnDisable">
        {{ doneBtnTitle | translate }}
    </button>
</ng-template>
