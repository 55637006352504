import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FinsteinTooltipModule } from '../../directives/finstein-tooltip/finstein-tooltip.module';
import { BaseModalComponent } from './base-modal.component';
import { ActionConfirmComponent } from './components/actions/action-confirm/action-confirm.component';
import { ActionFormButtonsComponent } from './components/actions/action-form-buttons/action-form-buttons.component';
import { PlainTextContentComponent } from './components/content/plain-text-content/plain-text-content.component';
import { CircleDangerComponent } from './components/header/circle-danger/circle-danger.component';
import { CircleEditComponent } from './components/header/circle-edit/circle-edit.component';
import { CircleInfoComponent } from './components/header/circle-info/circle-info.component';
import { CircleWarnComponent } from './components/header/circle-warn/circle-warn.component';
import { CustomCircleIconComponent } from './components/header/custom-circle-icon/custom-circle-icon.component';
import {MatDialogModule} from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
    declarations: [
        BaseModalComponent,
        CircleInfoComponent,
        CircleWarnComponent,
        CircleDangerComponent,
        CircleEditComponent,
        CustomCircleIconComponent,
        PlainTextContentComponent,
        ActionFormButtonsComponent,
        ActionConfirmComponent,
    ],
    imports: [
        CommonModule,
        TranslateModule,
        MatDialogModule,
        RouterModule,
        FinsteinTooltipModule,
        MatTooltipModule
    ],
    exports: [
        BaseModalComponent,
        CircleInfoComponent,
        CircleWarnComponent,
        CircleDangerComponent,
        CircleEditComponent,
        CustomCircleIconComponent,
        ActionFormButtonsComponent,
        PlainTextContentComponent,
        ActionConfirmComponent,
        MatDialogModule,
        TranslateModule,
    ],
})
export class BaseModalModule {}
