import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'finstein-plain-text-content[text]',
    template: `<div class="text-center" [innerHtml]="text | translate"></div>`,
    styles: ['div { color: #8b8b8b }']
})
export class PlainTextContentComponent implements OnInit {
    @Input()
    text: string;

    ngOnInit(): void {
        // Eliminates the possibility of marking undefined on the screen
        if (this.text === undefined) {
            this.text = '';
        }
    }
}
