import { Component, Input } from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';

export interface ErrorMessages {
    pattern?: string;
    required?: string;
}

@Component({
    selector: 'app-input-error',
    templateUrl: './input-error.component.html',
    styleUrls: ['./input-error.component.scss']
})
export class InputErrorComponent {

    @Input() formGroup: any;
    @Input() field: string;
    @Input() messages: ErrorMessages;
    @Input() passMatchCheck = false;
    @Input() errorCode = 'notSame';
    /** This attribute displays the gray mandatory attribute text below the input */
    @Input() showRequiredValidator = false;
    /** This attribute leaves a space below the entry, which will be the space for the error to appear */
    @Input() showEmpty = true;

    constructor() {
    }

    hasSomeError() {
        if (this.formGroup.get(this.field)) {
            return this.formGroup.get(this.field).invalid
                && (this.formGroup.get(this.field).dirty || this.formGroup.get(this.field).touched);
        }
    }

    passwordsDoesntMatch() {
        if (this.passMatchCheck) {
            return this.formGroup.hasError(this.errorCode) && this.formGroup.get('confirmPassword').dirty;
        }
        return false;
    }

    required() {
        const validator = this.formGroup?.get(this.field)?.validator;
        return this.showRequiredValidator && validator && validator({} as AbstractControl)?.required;
    }

    errors() {
        return Object.keys((this.formGroup.get(this.field) as UntypedFormControl).errors);
    }
}
