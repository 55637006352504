import { Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import {MatDialogRef} from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'finstein-base-modal',
    templateUrl: './base-modal.component.html',
    styleUrls: ['./base-modal.component.scss'],
})
export class BaseModalComponent implements OnInit, OnDestroy {
    @Input()
    title: string;

    @Input()
    disableClose = false;

    @Input()
    bodyClass = 'mr-6 ml-6';

    @Input()
    actionPosition: 'start' | 'center' | 'end' = 'center';
    hideHeader = false;
    beforeClosedSubs: Subscription;

    buttonCloseTranslation = null;

    @Input()
    showActions = true;

    constructor(private dialogRef: MatDialogRef<any>,
                elementRef: ElementRef,
                private translateService: TranslateService) {
        /* Remove the component label */
        elementRef.nativeElement.title = '';
        this.buttonCloseTranslation = this.translateService.instant('system.close');
    }

    dialogClose() {
        this.dialogRef.close();
    }

    ngOnInit(): void {
        this.beforeClosedSubs = this.dialogRef.beforeClosed().subscribe( () => {
            this.hideHeader = true;
        });
        // Eliminates the possibility of marking undefined on the screen
        if (this.title === undefined) {
            this.title = '';
        }
    }

    ngOnDestroy(): void {
        this.beforeClosedSubs?.unsubscribe();
    }

    get justifyContent() {
        return `justify-content: ${this.actionPosition}`;
    }
}
