/* src/app/core/components/input-error/input-error.component.scss */
small {
  padding-left: 1px;
}
.icon-error {
  position: relative;
  width: auto;
  bottom: 31px;
  margin-top: 0;
  float: right;
  margin-right: 8px;
  color: #DC3545;
}
.error-message {
  height: 20px;
}
/*# sourceMappingURL=input-error.component-EWSBUE6Y.css.map */
