import {NgModule} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {InputErrorComponent} from './input-error.component';
import {CommonModule} from '@angular/common';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule
    ],
    declarations: [
        InputErrorComponent
    ],
    exports: [
        InputErrorComponent
    ]
})
export class InputErrorModule {
}
