import { Component, EventEmitter, Input, Output } from '@angular/core';

/**
 * ActionConfirmComponent
 * 
 * This component is used to display a confirmation dialog with configurable buttons.
 * It supports two main states: 'confirm' and 'done', and offers various options to customize 
 * the buttons displayed in the user interface.
 * 
 * @selector finstein-action-confirm[type]
 * @example
 * <finstein-action-confirm
 *    [type]="'confirm'"
 *    [doneBtnTitle]="'Done'"
 *    [yesBtnTitle]="'Yes'"
 *    [noBtnTitle]="'No'"
 *    (doneClick)="onDone()"
 *    (yesClick)="onYes()"
 *    (noClick)="onNo()">
 * </finstein-action-confirm>
 */
@Component({
    selector: 'finstein-action-confirm[type]',
    templateUrl: './action-confirm.component.html',
    styleUrls: ['./action-confirm.component.scss'],
})
export class ActionConfirmComponent {
    /**
     * Defines the state type of the action dialog.
     * Possible values:
     * - 'confirm': Displays a confirmation dialog with "Yes" and "No" buttons.
     * - 'done': Displays a dialog with a "Done" button.
     * 
     * @type {'confirm' | 'done'}
     */
    @Input() type: 'confirm' | 'done';

    /**
     * Text displayed on the "Done" button when the type is 'done'.
     * Default: 'system.done'
     */
    @Input() doneBtnTitle = 'system.done';

    /**
     * Disables the "Done" button when set to true.
     * Default: false
     */
    @Input() doneBtnDisable = false;

    /**
     * Text displayed on the "Yes" button when the type is 'confirm'.
     * Default: 'system.yes'
     */
    @Input() yesBtnTitle = 'system.yes';

    /**
     * Disables the "Yes" button when set to true.
     * Default: false
     */
    @Input() yesBtnDisable = false;

    /**
     * Text displayed on the "No" button when the type is 'confirm'.
     * Default: 'system.no'
     */
    @Input() noBtnTitle = 'system.no';

    /**
     * Disables the "No" button when set to true.
     * Default: false
     */
    @Input() noBtnDisable = false;

    /**
     * Value to be emitted to close the dialog when the "Done" button is clicked.
     * Default: true
     */
    @Input() doneDialogCloseValue: any = true;

    /**
     * Value to be emitted to close the dialog when the "Yes" button is clicked.
     * Default: true
     */
    @Input() yesDialogCloseValue: any = true;

    /**
     * Value to be emitted to close the dialog when the "No" button is clicked.
     * Default: false
     */
    @Input() noDialogCloseValue: any = false;

    /**
     * Event emitted when the "Done" button is clicked.
     * Used only when the action type is 'done'.
     * @type {EventEmitter<void>}
     */
    @Output() doneClick = new EventEmitter<void>();

    /**
     * Event emitted when the "Yes" button is clicked.
     * Used only when the action type is 'confirm'.
     * @type {EventEmitter<void>}
     */
    @Output() yesClick = new EventEmitter<void>();

    /**
     * Event emitted when the "No" button is clicked.
     * Used only when the action type is 'confirm'.
     * @type {EventEmitter<void>}
     */
    @Output() noClick = new EventEmitter<void>();


    get isConfirmationDialog() {
        return this.type === 'confirm';
    }

    onDoneClick() {
        this.doneClick.emit();
    }

    onYesClick() {
        this.yesClick.emit();
    }

    onNoClick() {
        this.noClick.emit();
    }

}
