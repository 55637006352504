import { Component } from '@angular/core';

@Component({
    selector: 'finstein-circle-danger',
    template: `
        <finstein-custom-circle-icon color="danger-color">
            <i class="material-icons" icon>dangerous</i>
        </finstein-custom-circle-icon>
    `,
})
export class CircleDangerComponent {}
