import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'finstein-action-form-buttons',
    templateUrl: './action-form-buttons.component.html',
    styleUrls: ['./action-form-buttons.component.scss'],
})
export class ActionFormButtonsComponent {
    @Output() save = new EventEmitter<void>();
    @Output() cancel = new EventEmitter<void>();

    @Input() saveBtnTitle = 'system.save';
    @Input() saveBtnDisable = false;
    @Input() cancelBtnTitle = 'system.cancel';
    @Input() cancelBtnDisable = false;
    @Input() showCancelBtn = true;

    @Input() saveAdditionalClass = 'btn-primary';
    @Input() cancelAdditionalClass = 'btn-secondary';

    onSave() {
        this.save.emit();
    }

    onCancel() {
        this.cancel.emit();
    }
}
