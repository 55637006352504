import {
    AfterViewInit,
    Directive,
    ElementRef,
    HostListener,
    Input,
    OnDestroy,
    Renderer2,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { TOOLTIP_SIZE_BY_LENGTH } from 'src/app/app.constants';

@Directive({
    selector: '[finsteinTooltip]',
})
export class FinsteinTooltipDirective implements AfterViewInit, OnDestroy {
    currentDomElement: Document;

    _tooltipText: string;
    _tooltipDisabled: boolean;
    _tooltipPos:
        | 'up'
        | 'left'
        | 'right'
        | 'down'
        | 'up-left'
        | 'up-right'
        | 'down-left'
        | 'down-right' = 'up';
    _tooltipAutoSize = false;
    _tooltipSize: 'small' | 'medium' | 'large' | 'xlarge' | 'fit';
    _showTooltipIn: Document;
    _translatedText: string;
    _enableTooltip = true;
    _forceEnableTooltip = false;

    constructor(
        public elementRef: ElementRef,
        public renderer: Renderer2,
        public translate: TranslateService
    ) {
        this.currentDomElement = this.elementRef.nativeElement;
    }

    ngAfterViewInit(): void {
        if (this._tooltipDisabled) {
            this.renderer.addClass(this.domElement, 'tooltip-pewter-disabled');
        } else {
            this.renderer.addClass(this.domElement, 'tooltip-pewter');
        }
        this.renderer.addClass(this.domElement, 'tooltip-text');
        this.forceEnableTooltip = this._forceEnableTooltip;
    }

    ngOnDestroy(): void {
        this.renderer.removeAttribute(this.domElement, 'data-balloon-pos');
        this.renderer.removeAttribute(this.domElement, 'data-balloon-length');
        this.renderer.removeAttribute(this.domElement, 'aria-label');
    }

    @HostListener('mouseover')
    setToolTip() {
        if (this._enableTooltip) {
            this.translateValue.subscribe().unsubscribe();
            this.tooltipDisabled = this._tooltipDisabled;
            this.tooltipPos = this._tooltipPos;
            this.tooltipAutoSize = this._tooltipAutoSize;
            this.tooltipSize = this._tooltipSize;
            this.showTooltipIn = this._showTooltipIn;
            this.enableTooltip = this._enableTooltip;
        }
    }

    @Input('finsteinTooltip')
    set tooltipText(text: string) {
        this._tooltipText = text;
    }

    @Input()
    set tooltipDisabled(value: boolean) {
        this._tooltipDisabled = value;
    }

    @Input()
    set tooltipPos(
        value:
            | 'up'
            | 'left'
            | 'right'
            | 'down'
            | 'up-left'
            | 'up-right'
            | 'down-left'
            | 'down-right'
            | any
    ) {
        this._tooltipPos = value;
        this.renderer.setAttribute(
            this.domElement,
            'data-balloon-pos',
            this._tooltipPos
        );
    }

    @Input()
    set enableTooltip(value: boolean) {
        this._enableTooltip = value;
        if (!value && !this._forceEnableTooltip) {
            this.renderer.removeAttribute(this.domElement, 'data-balloon-pos');
        }
    }

    @Input()
    set forceEnableTooltip(value: boolean) {
        this._forceEnableTooltip = value;
        if (value) {
            this.renderer.setAttribute(
                this.domElement,
                'data-balloon-visible',
                'true'
            );
            this.setToolTip();
        }
    }

    @Input()
    set tooltipAutoSize(value: boolean) {
        this._tooltipAutoSize = value;
        if (this._tooltipAutoSize) {
            this.tooltipSize = TOOLTIP_SIZE_BY_LENGTH(this._translatedText);
        }
    }

    @Input()
    set tooltipSize(size: 'small' | 'medium' | 'large' | 'xlarge' | 'fit') {
        this._tooltipSize = size;
        if (this._tooltipSize) {
            this.renderer.setAttribute(
                this.domElement,
                'data-balloon-length',
                this._tooltipSize
            );
        }
    }

    @Input()
    set showTooltipIn(ref: any) {
        this._showTooltipIn = ref;
    }

    get domElement() {
        return this._showTooltipIn
            ? this._showTooltipIn
            : this.currentDomElement;
    }

    private get translateValue() {
        if (this._tooltipText?.length) {
            return this.translate.get(this._tooltipText).pipe(
                take(1),
                tap((text) => {
                    this._translatedText = text;
                    this.renderer.setAttribute(
                        this.domElement,
                        'aria-label',
                        this._translatedText
                    );
                })
            );
        } else {
            return of();
        }
    }
}
