import { Component, Input } from '@angular/core';

@Component({
    selector: 'finstein-custom-circle-icon[color]',
    template: `
        <div class="row justify-content-center">
            <span
                class="circle-modal animate-grow-circle"
                [ngClass]="color"
            >
                <ng-content select="[icon]"></ng-content>
            </span>
        </div>
    `,
})
export class CustomCircleIconComponent {
    @Input()
    color: string;
}
