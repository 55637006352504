import { FinsteinTooltipDirective } from './finstein-tooltip.directive';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    declarations: [FinsteinTooltipDirective],
    imports: [CommonModule, TranslateModule],
    exports: [FinsteinTooltipDirective],
})
export class FinsteinTooltipModule {}
