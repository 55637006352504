import { Component } from '@angular/core';

@Component({
    selector: 'finstein-circle-warn',
    template: `
        <finstein-custom-circle-icon color="warning-color">
            <i class="material-icons" icon>warning</i>
        </finstein-custom-circle-icon>
    `,
})
export class CircleWarnComponent {}
